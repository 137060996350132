<template>
    <div>
        
    </div>
</template>
<script>
export default {
    created() {
        // this.iJsBridge.setResult({});
        // this.iJsBridge.close();
        if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler(
                "SmGeneral.closeWebSite",
            );
        }else{
        this.iJsBridge.setResult({});
            this.iJsBridge.close();
        }
    }
}
</script>